
import { defineComponent } from "vue";
import { showMyLocationsModal } from "../Modals/ModalController";
import { TYPE, useToast } from "vue-toastification";
import { getCoordinates } from "../../common/common";
import CannotGetLocationWarning from "../CannotGetLocationWarning.vue";

export default defineComponent({
  components: { CannotGetLocationWarning },
  data() {
    return {
      failedToGetCoordinates: false as boolean,
      tryingToLoadAgain: false as boolean,
    };
  },
  async mounted() {
    if (
      this.location?.placeLat &&
      this.location?.placeLong &&
      this.location?.nickname &&
      this.radius
    ) {
      this.coordinates.lat = undefined;
      this.coordinates.long = undefined;
      this.coordinates.lat = this.location.placeLat;
      this.coordinates.long = this.location.placeLong;
      this.$store.commit(
        "setEventWizardLocationNicknameContext",
        this.location.nickname
      );
    } else if (this.location?.nickname === "current-location") {
      this.coordinates.lat = undefined;
      this.coordinates.long = undefined;
      this.$store.commit(
        "setEventWizardLocationNicknameContext",
        "Custom Location"
      );
      this.setCoordinates();
    }
  },
  watch: {
    location: {
      deep: true,
      handler(newLocation) {
        if (newLocation?.nickname === "current-location") {
          this.setCoordinates();
          this.$store.commit(
            "setEventWizardLocationNicknameContext",
            "Custom Location"
          );
        } else if (newLocation?.nickname === "new-location") {
          this.$emit("disable-next");
          showMyLocationsModal();
        } else {
          this.$store.commit(
            "setEventWizardLocationNicknameContext",
            newLocation.nickname
          );
          this.coordinates = {
            lat: newLocation.placeLat,
            long: newLocation.placeLong,
          };
        }
        if (
          this.coordinates.lat !== undefined &&
          this.coordinates.long !== undefined &&
          this.radius &&
          +this.radius > 0 &&
          this.location?.nickname &&
          this.location?.nickname !== "add-location" &&
          this.location?.placeLat !== null &&
          this.location?.placeLong !== null
        ) {
          this.$emit("enable-next");
        } else {
          this.$emit("disable-next");
        }
      },
    },
    coordinates: {
      deep: true,
      handler(n) {
        if (
          n.lat !== undefined &&
          n.long !== undefined &&
          this.radius &&
          +this.radius > 0 &&
          this.location?.nickname &&
          this.location?.nickname !== "add-location" &&
          this.location?.placeLat !== null &&
          this.location?.placeLong !== null
        ) {
          this.$emit("enable-next");
        } else {
          this.$emit("disable-next");
        }
      },
    },
    radius: {
      handler(n) {
        if (
          this.radius &&
          +this.radius > 0 &&
          this.location?.nickname &&
          this.location?.nickname !== "add-location" &&
          this.location?.placeLat !== null &&
          this.location?.placeLong !== null
        ) {
          this.$emit("enable-next");
        } else {
          this.$emit("disable-next");
        }
      },
    },
  },
  computed: {
    coordinates: {
      get() {
        return this.$store.state.eventWizardContext.coordinates;
      },
      set(coordinates) {
        this.$store.commit("setEventWizardCoordinatesContext", {
          lat: coordinates.lat,
          long: coordinates.long,
        });
      },
    },
    location: {
      get() {
        return this.$store.state.locationContext;
      },
      set(location) {
        this.$store.commit("setLocationContext", location);
      },
    },
    locations: {
      get() {
        return this.$store.state.userLocations;
      },
      set(locations) {
        this.$store.commit("setUserLocations", locations);
      },
    },
    radius: {
      get() {
        return this.$store.state.eventWizardContext.radius;
      },
      set(radius: string) {
        this.$store.commit("setEventWizardRadiusContext", radius);
      },
    },
  },
  methods: {
    async setCoordinates() {
      try {
        this.coordinates = await getCoordinates();
        if (!this.coordinates.lat || !this.coordinates.long) {
          const toast = useToast();
          toast("Unable to get location.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
          this.tryingToLoadAgain = false;
          this.failedToGetCoordinates = true;
        } else {
          this.failedToGetCoordinates = false;
          this.$store.commit(
            "setEventWizardLocationNicknameContext",
            "Custom Location"
          );
          this.location.nickname = "Custom Location";
          this.location.placeLat = this.coordinates.lat;
          this.location.placeLong = this.coordinates.long;
          this.location.address = null;
        }
      } catch (e) {
        console.log(e);
        const toast = useToast();
        toast("No coordinates set. Tap 'Set Coordinates' and try again.", {
          timeout: 5000,
          type: TYPE.ERROR,
          hideProgressBar: true,
        });
      }
    },
  },
});
